import ReactDOM from 'react-dom';

import App from 'App';
import * as serviceWorker from 'serviceWorker';

import config from 'config';

const { application: { environment } } = config;
ReactDOM.render(App, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

if (environment === 'prod') {
    serviceWorker.register();
} else {
    serviceWorker.unregister();
}
